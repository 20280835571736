import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BlobExplorer = () => {
    const [currentDirectory, setCurrentDirectory] = useState('');
    const [blobs, setBlobs] = useState([]);
    const [latestFile, setLatestFile] = useState(null);

    // Fetch blobs from the API
    const fetchBlobs = async (directory) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/echo/releases`, {
                params: { directory: directory || '' }
            });
            const blobs = response.data;
            setBlobs(blobs);

            // Filter only files (not directories)
            const files = blobs.filter(blob => !blob.isDirectory);

            // Find the latest file by last modified date if there are files
            if (files.length > 0) {
                const latest = files.reduce((prev, current) => (
                    prev.lastModified > current.lastModified ? prev : current
                ));
                setLatestFile(latest);
            } else {
                setLatestFile(null); // No files in the directory
            }
        } catch (error) {
            console.error('Error fetching blobs', error);
        }
    };

    useEffect(() => {
        fetchBlobs(currentDirectory);
    }, [currentDirectory]);

    return (
        <div>
            {/* Stage Front's Echo Browser title with logo */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <img src="/logo.png" alt="Stage Front Logo" style={{ width: '50px', marginRight: '15px' }} />
                <h1>Stage Front's Echo Browser</h1>
            </div>

            {currentDirectory &&(
                <h2>Releases for: {currentDirectory}</h2>
            )}
            
            {/* Show Latest File only if there are files */}
            {latestFile && (
                <div style={{ marginBottom: '20px' }}>
                    <h3>Latest File</h3>
                    <p>
                        📄 {latestFile.name} <br />
                        Last Modified: {new Date(latestFile.lastModified).toLocaleString()} <br />
                        <a href={latestFile.downloadUrl} target="_blank" rel="noopener noreferrer">
                            [Download Latest]
                        </a>
                    </p>
                </div>
            )}

            <ul>
                {currentDirectory && (
                    <li>
                        <button onClick={() => setCurrentDirectory('')}>
                            [Back to Root]
                        </button>
                    </li>
                )}
                {blobs.map((blob) => (
                    <li key={blob.name}>
                        {blob.isDirectory ? (
                            <button onClick={() => setCurrentDirectory(blob.name)}>
                                📁 {blob.name}
                            </button>
                        ) : (
                            <>
                                📄 {blob.name}{' '}
                                <a href={blob.downloadUrl} target="_blank" rel="noopener noreferrer">
                                    [Download]
                                </a>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BlobExplorer;
